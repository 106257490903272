/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
}

body {
  background-color: #0c1d3a; /* Dark blue */
  color: #fff;
}

a {
  color: #ffa500; /* Orange color */
  text-decoration: none;
}

ul {
  list-style: none;
}

/* General Sidebar Styles */
.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  background-color: #0c1d3a;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar.active {
  width: 300px;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.sidebar-logo {
  width: 150px;
}

.close-btn {
  font-size: 30px;
  cursor: pointer;
  color: #fff;
}

/* Sidebar Menu Styles */
.sidebar-menu {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Add borders between each sidebar item */
.sidebar-menu li {
  text-align: left;
  padding: 8px 20px;
  border-bottom: 1px solid #ffa500; /* Adds an orange line under each item */
}



.sidebar-menu a {
  color: #ffa500;
  font-size: 18px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.sidebar-menu a:hover {
  color: #fff;
}

/* Submenu Styles */
.submenu {
  max-height: 0; /* Initially hidden */
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding-left: 20px;
}

.submenu.open {
  max-height: 500px; /* Adjust based on your content */
}

.submenu li {
  padding: 8px 0;
}

.submenu li a {
  font-size: 16px;
  color: #ffa500;
}

.submenu li a:hover {
  color: #fff;
}

/* Sidebar Toggle Button */
.open-sidebar {
  font-size: 30px;
  cursor: pointer;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  color: #ffa500;
}

.open-sidebar:hover {
  color: #fff;
}


/* Navbar */
.navbar {
  position: absolute; /* Overlay on top of carousel */
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 10; /* Ensures it's above the carousel */
}

.logo-container {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border: 2px solid #ffa500; /* Orange border for logo box */
  border-radius: 10px; /* Rounded corners */
  padding: 10px; /* Padding around the logo */
}

.logo {
  height: 70px; /* Adjust logo size as needed */
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  font-size: 18px;
  color: #fff;
  padding: 5px 10px;
  border: 2px solid #fff; /* Add outline */
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better visibility */
  transition: all 0.3s ease;
}

.nav-links a:hover {
  color: #ffa500;
  border-color: #ffa500;
  background-color: rgba(255, 165, 0, 0.5); /* Change background on hover */
}

/* Hero Section */
.carousel {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100vh; /* Fullscreen carousel */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff; /* White text for hero section */
}

.carousel h1 {
  font-size: 50px;
  color: #ffa500; /* Orange color for the heading */
  margin-bottom: 10px;
}

.carousel p {
  font-size: 24px;
  margin-bottom: 20px;
}

.carousel .btn {
  padding: 10px 20px;
  background-color: #ffa500;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel .btn:hover {
  background-color: #e69500;
}

/* Carousel Text Box */
.carousel-text-box {
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  max-width: 80%; /* Responsive width */
  margin: 0 auto; /* Center alignment */
}

/* Dots for Carousel */
.carousel-dots {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #ffa500; /* Highlighted dot */
}

/* About Section */
.about-section {
  padding: 50px;
  background-color: #fff;
  text-align: left;
}

.about-section h2 {
  max-width: 180px;
  margin-bottom: 30px;
  color: #1e2a46;
  padding: 10px; /* Adds space inside the box */
  background-color: #ffa500; /* Background color for the contact box */
  border: 2px solid #1e2a46; /* Border color matching the theme */
  border-radius: 10px; /* Curves the corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for depth */
  margin-bottom: 20px; /* Adds spacing between contact boxes */
  text-align: center;
}

.about-section p {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
}

/* Services Section */
.services-section {
  padding: 50px;
  background-image: url('./assets/courier-driver-4886839_1280.jpg'); /* Update with your image path */
  background-size: cover; /* Makes the image cover the entire section */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  position: relative; /* Allows the use of pseudo-elements */
}

/* Add this pseudo-element for a slight overlay effect */
.services-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  z-index: 1; /* Ensures the overlay sits above the background image */
}

.services-section h2 {
  margin-bottom: 30px;
  color: #0c1d3a;
  position: relative; /* To ensure it's above the overlay */
  z-index: 2; /* Puts the heading above the overlay */
  
  /* Box styling */
  padding: 15px 20px; /* Padding around the text */
  background: rgba(255, 255, 255, 0.8); /* Light background for the box */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 100px;
  position: relative; /* To ensure it sits above the overlay */
  z-index: 2; /* Puts the services container above the overlay */
}

.service {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 250px;
  text-align: center;
  position: relative; /* Ensures this is above the overlay */
  z-index: 3; /* Ensures the service cards are above the overlay */
  
  /* Optional: Add a border to service cards */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Light border around service cards */
}

.service img {
  width: 80px;
  margin-bottom: 15px;
}

.service h3 {
  margin-bottom: 15px;
  color: #0c1d3a;
}

.service p {
  color: #666;
  font-size: 0.9em;
  line-height: 1.4;
}
/* Clients Section */
.clients-section, .partners-section {
  padding: 50px;
  background-image: url('./assets/2151003787.jpg'); /* Update with your image path */
  background-size: cover; /* Makes the image cover the entire section */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  position: relative; /* Allows the use of pseudo-elements */
  /* Add an overlay similar to services section */
}

.clients-section::before, .partners-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Slight overlay effect */
  z-index: 1;
}

.clients-section h2, .partners-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #0c1d3a; /* Make the heading white for better contrast */
  position: relative; /* Position relative to the section */
  z-index: 2; /* Ensures it appears above the overlay */
  
  /* Box styling for heading similar to services-section */
  padding: 15px 20px;
  background: rgba(255, 255, 255, 0.8); /* Light background box */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.clients-container, .partners-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 100px;
  position: relative;
  z-index: 2;
}

.client, .partner {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 250px; /* You can adjust this based on your design */
  text-align: center; /* Center text */
  position: relative;
  z-index: 3;

  /* Optional: Add a border to client/partner cards */
  border: 1px solid rgba(0, 0, 0, 0.1);

  display: flex; /* Use flexbox for centering */
  flex-direction: column; /* Stack logo and text vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
}

.client-logo, .partner-logo {
  width: 200px; /* Adjusted width */
  position: relative;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.client-logo:hover, .partner-logo:hover {
  transform: scale(1.1);
}

.client p, .partner p {
  font-size: 1.2em;
  position: relative;
  color: #666;
  line-height: 1.4;
  margin-top: 10px; /* Added margin for spacing */
  text-align: center; /* Center the text */
}



/* Contact Section */
.contact-section {
  padding: 50px;
  background-image: url('./assets/word_map.jpg'); /* Update with your image path */
  background-size: cover; /* Makes the image cover the entire section */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  position: relative; /* Allows the use of pseudo-elements */
}

.contact-section h2 {
  max-width: 180px;
  margin-bottom: 30px;
  text-align: center;
  color: #ffa500;
  padding: 10px; /* Adds space inside the box */
  background-color: #1e2a46; /* Background color for the contact box */
  border: 2px solid #ffa500; /* Border color matching the theme */
  border-radius: 10px; /* Curves the corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for depth */
  margin-bottom: 20px; /* Adds spacing between contact boxes */
  
}

.contact-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.contact-details {
  max-width: 400px;
  text-align: center;
  padding: 20px; /* Adds space inside the box */
  background-color: #1e2a46; /* Background color for the contact box */
  border: 2px solid #ffa500; /* Border color matching the theme */
  border-radius: 10px; /* Curves the corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for depth */
  margin-bottom: 20px; /* Adds spacing between contact boxes */
}

.contact-details p {
  font-size: 1.1em;
  color: #ffa500;
}

.map-container-side-by-side {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Adjusts spacing between addresses and maps */
}

.map-container {
  width: 45%; /* Set width to allow both maps to fit side by side */
  height: 400px; /* Adjust height as necessary */
  border: 5px solid #ccc; /* You can adjust the border thickness and color */
  border-radius: 20px; /* Curves the corners */
  border-color:#1e2a46 ;
  overflow: hidden; /* Ensures the content inside the container is clipped to fit the rounded borders */
}

.map-container iframe {
  width: 100%;
  height: 100%;
}

/* Footer */
.main-footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}

.main-footer p {
  margin: 0;
  padding: 10px;
  font-size: 0.9em;
}

.footer-logo {
  width: 100px;
  margin-top: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .services-container, .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .service, .contact-details, .map-container {
    width: 100%;
    max-width: 500px;
  }
  
  /* Ensure sidebar doesn't take too much space on mobile */
  .sidebar.active {
    width: 100%;
  }
}
